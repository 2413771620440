import React from "react";
import { LinkWithBorder } from "./Link";
import SearchBox from "./SearchBox";
import MoreButton from "./MoreButton";

function Navbar({ currentPage, location, isMenuOpen, onMenuButtonClick, includeConsulting }) {
  return (
    <div className="flex flex-none bg-white border-b border-gray-200  inset-x-0 z-100 h-16 items-center fixed z-10 top-0">
      <div className="w-full max-w-screen-xl relative mx-auto px-6">
        <div className="flex items-center justify-start -mx-6">
          <a href="/">
            <img
              className="h-12 w-12 mx-6"
              src="/cs-blue-gray.svg"
              alt="Christian Sepulveda"
            />
          </a>
          <div className="hidden md:flex">
            <ul className="list-none flex flex-row justify-center text-gray-600 h-full">
              <LinkWithBorder
                currentPage={currentPage}
                link="/articles"
                label="articles"
              />
              <LinkWithBorder
                currentPage={currentPage}
                link="/repos"
                label="repos"
              />
              <LinkWithBorder
                currentPage={currentPage}
                link="/about"
                label="about me"
              />
            </ul>
          </div>

          <div className="flex flex-grow justify-end ">
            <div className="w-full md:w-1/2 lg:px-6">
              <SearchBox location={location} />
            </div>

            <div className="flex items-center px-6">
              <div className="flex justify-center items-center text-gray-500">
                <a
                  className="block flex items-center hover:text-gray-700 mr-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/csepulv"
                >
                  <i className="fab fa-lg fa-github" />
                </a>
                <a
                  className="block flex items-center hover:text-gray-700 mr-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/csepulv"
                >
                  <i className="fab fa-lg fa-twitter" />
                </a>
                <a
                  className="block flex items-center hover:text-gray-700 mr-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/christiansepulveda/"
                >
                  <i className="fab fa-lg fa-linkedin" />
                </a>
                <MoreButton isOpen={isMenuOpen} onClick={onMenuButtonClick}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
