import React from "react";
import { Link } from "./Link";

function NavMenu({ currentPage, isOpen }) {
  return (
    <div className={`flex sm:pt-16 md:hidden ${!isOpen ? "hidden" : ""}`}>
      <div className="w-full  bg-white border-b border-gray-200">
        <ul className="list-none flex flex-col justify-center text-gray-600 h-full">
          <Link currentPage={currentPage} link="/articles" label="articles" />
          <Link currentPage={currentPage} link="/repos" label="repos" />
          <Link currentPage={currentPage} link="/about" label="about me" />
        </ul>
      </div>
    </div>
  );
}
export default NavMenu;
