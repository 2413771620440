import React, { useContext, useState, useEffect } from "react";

import Page from "./Page";
import Articles from "./Articles";
import SearchContext from "./SearchContext";

function ArticlesPage({ location }) {
  const [articles, setArticles] = useState([]);
  const searchEngine = useContext(SearchContext);
  useEffect(
    () => {
      setArticles(searchEngine.search(location));
    },
    [location, searchEngine]
  );
  return (
    <Page currentPage="/articles" location={location}>
      <Articles articles={articles} />
    </Page>
  );
}
export default ArticlesPage;
