import React, { useState } from "react";
import PropTypes from "prop-types";

import MorphReplace from "./external/react-svg-morph/MorphReplace";

function MoreIcon({ color }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="bars"
      className="svg-inline--fa fa-bars fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
      />
    </svg>
  );
}

function CloseIcon({ color }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times"
      className="svg-inline--fa fa-times fa-w-11"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 352 512"
    >
      <path
        fill={color}
        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
      />
    </svg>
  );
}

export function MoreButton({
  onClick,
  height,
  width,
  color,
  hoverColor,
  isOpen
}) {
  const [iconColor, setIconColor] = useState(color);
  function onMouseEnter() {
    setIconColor(hoverColor);
  }
  function onMouseLeave() {
    setIconColor(color);
  }

  return (
    <button
      className="visible md:hidden hover:text-gray-700"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MorphReplace
        width={height}
        height={width}
        rotation="none"
        preserveAspectRatio="none"
      >
        {isOpen ? (
          <CloseIcon key="x-close" color={hoverColor} />
        ) : (
          <MoreIcon key={`icon-${iconColor}`} color={iconColor} />
        )}
      </MorphReplace>
    </button>
  );
}

MoreButton.defaultProps = {
  width: 20,
  height: 20,
  color: "#a0aec0",
  hoverColor: "#4a5568"
};

MoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default MoreButton;
