import lunr from "lunr";
import qs from "qs";

import articles from "./medium-articles";

articles.sort((a, b) => b.publishedAt - a.publishedAt);

export default function makeSearchEngine() {
  const index = lunr(function() {
    this.ref("articleId");
    this.field("title");
    this.field("text");

    articles.forEach(function(doc) {
      this.add(doc);
    }, this);
  });

  function extractQuery(location) {
    if (!location.search) return;

    const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    return parsedSearch.query.replace(/"/g, "");
  }

  return {
    extractQuery,
    search: function(location) {
      const query = extractQuery(location);
      if (!query) return articles;

      const matches = index.search(query);
      const results = [];
      matches.forEach(match => {
        const article = articles.find(a => a.articleId === match.ref);
        results.push(article);
      });
      return results;
    }
  };
}
