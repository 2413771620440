import React from "react";

import Page from "./Page";
import Articles from "./Articles";
import articles from "./medium-articles";

function Home() {
  articles.sort((a, b) => b.publishedAt - a.publishedAt);
  return (
    <Page currentPage="/">
      <div className="flex flex-row flex-wrap my-16 mx-auto w-4/5 justify-center">
        <div className="sm:w-full md:w-2/5 mx-4">
          <div className="my-8 align-middle text-4xl font-heading text-gray-700">
            CHRISTIAN SEPULVEDA
          </div>
          <div className="text-gray-700 text-xl">
            espresso fanatic, coder (still), VP Engineering Bastille, …yeah,
            espresso comes first.{" "}
          </div>
        </div>
        <div className="sm:w-full md:w-2/5 bg-blue-400 p-16 mx-4 rounded-lg">
          <img src="/desk-items.svg" />
        </div>
      </div>
      <div className="w-1/3 mx-auto">
        <hr />
      </div>
      <div className="text-gray-700 text-xl text-center mt-8 -mb-8">Latest Articles</div>
      <Articles articles={articles.slice(0, 3)} />
      <div className="mx-32 text-right text-gray-700">
        <a href="/articles">more articles...</a>
      </div>
    </Page>
  );
}
export default Home;
