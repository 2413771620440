import React, { useState } from "react";
import Navbar from "./Navbar";
import NavMenu from "./NavMenu";

function Page({ currentPage, children, includeConsulting }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <Navbar
        currentPage={currentPage}
        isMenuOpen={isMenuOpen}
        onMenuButtonClick={() => setMenuOpen(!isMenuOpen)}
      />
      <NavMenu currentPage={currentPage} isOpen={isMenuOpen} />
      <div className="w-full mx-auto pt-16">{children}</div>
    </div>
  );
}
export default Page;
