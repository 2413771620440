import React from "react";
import Page from "./Page";
import styles from "./styles/AboutPage.module.scss";

function AboutPage() {
  return (
    <Page currentPage="/about">
      <div className="sm:mx-16 md:mx-auto w-4/5">
        <div className="flex flex-row flex-wrap my-8 mx-auto w-full justify-center">
          <div
            className={`sm:w-full md:w-2/5 mt-4 mb-8 mx-4 rounded-lg ${
              styles.image
            }`}
          />
          <div className="sm:w-full md:w-3/5 mx-8">
            <div className="mb-4 align-middle text-4xl font-heading text-gray-700">
              CHRISTIAN SEPULVEDA
            </div>
            <div className="text-gray-700 text-xl">
              <p>
                I build software. I've worked on a lot of projects, with
                numerous teams, and in a variety of industries. While I've
                primarily had a leadership role, I still love to code.
              </p>

              <p className="mt-8">
                As noted, I am a bit obsessed with coffee. Mind you, I would
                never say I am obsessed, but people who know me might. I know a
                lot about coffee, probably far more than is healthy. I study
                different coffee regions, varieties of beans, processing
                methods, grinder differences, pressure profiles, ... the list
                grows quickly. But the more I learn about coffee, the more I
                realize I know very little of what could be known. I also learn
                that many others know much, much more than me.
              </p>
              <p className="mt-8">
                The same is true with software. I know a lot about building
                software. And I keep learning and I keep making the effort to
                study. But the cup grows faster than I can fill it.
              </p>
              <p className="mt-8">
                That is a good thing. It keeps me engaged and (hopefully) it
                keeps me humble.
              </p>
            </div>
            <div className="text-gray-700 text-xl">
              <div className="w-1/2 mx-auto mt-8 text-gray-700 text-xl">
                <hr />
              </div>
              <p className="mt-8">
                I write and code, when I can. You can follow my writings at
              </p>
              <ul className="list-disc ml-4 mt-4">
                <li>
                  <a className="underline" href="https://christiansepulveda.medium.com/">
                    Medium
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    href="https://www.freecodecamp.org/news/author/christian/"
                  >
                    freecodecamp.org
                  </a>
                </li>
                <li>
                  <a className="underline" href="https://hackernoon.com/@csepulv">
                    Hackernoon
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </Page>
  );
}
export default AboutPage;
