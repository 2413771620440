import React from "react";

export function LinkWithBorder({ currentPage, link, label }) {
  return (
    <a href={link}>
      <li
        className={`p-5 ${
          currentPage === link
            ? "bg-blue-400 text-white border-b-4 border-blue-600"
            : "hover:bg-gray-200 hover:border-b-4 hover:border-gray-400 hover:text-gray-700"
        }`}
      >
        {label}
      </li>
    </a>
  );
}

export function Link({ currentPage, link, label }) {
  return (
    <a href={link}>
      <li
        className={`p-5 ${
          currentPage === link
            ? "bg-blue-400 text-white"
            : "hover:bg-gray-200 hover:text-gray-700"
          }`}
      >
        {label}
      </li>
    </a>
  );
}
