import React from "react";
import Page from "./Page";
import { Repo } from "./Cards";

import repos from "./github-repos-curated";

function ReposPage() {
  return (
    <Page currentPage="/repos">
      <div className="flex flex-row flex-wrap m-8">
        {repos.map(repo => (
          <Repo key={repo.name} item={repo} />
        ))}
      </div>
    </Page>
  );
}
export default ReposPage;
