import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import SearchContext from "./SearchContext";

function SearchBox({ history, location }) {
  const searchEngine = useContext(SearchContext);
  const [query, setQuery] = useState(searchEngine.extractQuery(location) || "");

  function updateQuery(event) {
    setQuery(event.target.value);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      const path = `/articles?query=${encodeURIComponent(query)}`;
      history.push(path);
    }
  }

  return (
    <div className="relative">
      <input
        id="docsearch"
        className="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 text-gray-700 placeholder-gray-500 rounded-lg bg-gray-200 py-2 pr-4 pl-10 block w-full appearance-none leading-normal"
        type="text"
        placeholder="Search articles..."
        value={query}
        onChange={updateQuery}
        onKeyPress={handleKeyPress}
      />
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <svg
          className="fill-current pointer-events-none text-gray-600 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
      </div>
    </div>
  );
}

export default withRouter(SearchBox);
