import React from "react";
import urlParse from "url-parse";
import { Calendar, Clock } from "react-feather";
import GitHubButton from "react-github-btn";
import moment from "moment";
import classnames from "classnames";
import AnimatedVisibility from "./AnimatedVisibility";
import styles from "./styles/Card.module.scss";
import theme from "./theme.scss";

const momentFormats = {
  sameDay: "[Today]",
  nextDay: "[Tomorrow]",
  nextWeek: "dddd",
  lastDay: "[Yesterday]",
  lastWeek: "[Last] ddd",
  sameElse: "MMM D, YYYY"
};

const sourceIcons = {
  pocket: "fa-get-pocket",
  medium: "fa-medium"
};

function buildImageUrl(previewImageId) {
  if (previewImageId && previewImageId.length > 0)
    return previewImageId.startsWith("http")
      ? previewImageId
      : `https://cdn-images-1.medium.com/max/250/${previewImageId}`;
  return "/browser.svg";
}

function NewWindowLink({ url, children }) {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      className={classnames(styles.link)}
      href={url}
      style={{ color: theme.colorText }}
    >
      {children}
    </a>
  );
}

function Publication({ item }) {
  const publicationName =
    item.publication.name && !item.publication.name.startsWith("http")
      ? item.publication.name
      : urlParse(item.publication.url).hostname;
  return (
    <div className={styles.publication}>
      <NewWindowLink url={item.publication.url}>
        <i
          className={classnames(
            "fab",
            sourceIcons[item.source],
            styles.sourceIcon
          )}
        />
        {publicationName}
      </NewWindowLink>
    </div>
  );
}

function ItemLink({ item, children }) {
  return <NewWindowLink url={item.url}>{children}</NewWindowLink>;
}

export function Repo({ item }) {
  return (
    <AnimatedVisibility isVisible={true}>
      <div className={classnames(styles.card, styles.repo)}>
        <div className={styles.contentBackground}>
          <div className={styles.repoGrid}>
            <ItemLink item={item}>
              <div className={styles.title}>{item.name}</div>
            </ItemLink>
            <div className={styles.snippet}>{item.description}</div>
            <div className={styles.publication}>{item.language}</div>
            <div className={classnames(styles.metadata, styles.metaStart)}>
              <GitHubButton
                href={`${item.url}/fork`}
                data-icon="octicon-repo-forked"
                data-show-count="true"
                aria-label={`Fork ${item.full_name} on GitHub`}
              >
                Fork
              </GitHubButton>
            </div>
            <div className={styles.metadata}>
              <GitHubButton
                href={item.url}
                data-icon="octicon-star"
                data-show-count="true"
                aria-label={`Star ${item.full_name} on GitHub`}
              >
                Star
              </GitHubButton>
            </div>
          </div>
        </div>
      </div>
    </AnimatedVisibility>
  );
}

export function Article({ item }) {
  return (
    <AnimatedVisibility isVisible={true}>
      <div className={classnames(styles.card, styles.article)}>
        <div className={styles.contentBackground}>
          <div className={styles.articleGrid}>
            <ItemLink item={item}>
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${buildImageUrl(item.previewImageId)})`
                }}
              />
            </ItemLink>
            <ItemLink item={item}>
              <div className={styles.title}>{item.title}</div>
            </ItemLink>
            <div className={styles.snippet}>
              {item.subtitle || item.text.substring(0, 200)}
            </div>
            <Publication item={item} />

            <div className={classnames(styles.metadata, styles.metaStart)}>
              <Clock className={styles.icon} />
              {`${Math.round(item.readingTime)} min`}
            </div>
            <div className={styles.metadata}>
              <Calendar className={styles.icon} />
              {moment(item.publishedAt).calendar(null, momentFormats)}
            </div>
          </div>
        </div>
      </div>
    </AnimatedVisibility>
  );
}
