import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./Home";
import Analytics from "react-router-ga";

import SearchContext from "./SearchContext";
import ArticlesPage from "./ArticlesPage";
import ReposPage from "./ReposPage";
import AboutPage from "./AboutPage";
import makeSearchEngine from "./search-engine";
import ConsultingPage from "./ConsultingPage";

function Routes() {
  return (
    <SearchContext.Provider value={makeSearchEngine()}>
      <BrowserRouter>
        <Analytics id="UA-84264124-4" debug>
          <Route path="/" exact component={Home} />
          <Route path="/articles" component={ArticlesPage} />
          <Route path="/repos" component={ReposPage} />
          <Route path="/about" component={AboutPage} />
        </Analytics>
      </BrowserRouter>
    </SearchContext.Provider>
  );
}
export default Routes;
