import React from "react";
import {Article} from "./Cards";
// import Article from "./Article";

function Articles({ articles }) {
  // <div className="mx-auto" style={{ width: 700 }}>

  return (
    <div className="flex flex-row flex-wrap mt-8 mx-auto">
      {articles.map(item => (
        <Article key={item.articleId} item={item} />
      ))}
    </div>
  );
}
export default Articles;
